<template>
  <section id="ads-view">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("settingTabs.ads") }}</h1>
        <h5 class="text-primary">
          {{ $t("code.visAds") }}
        </h5>
      </b-col>
      <b-row class="d-flex justify-content-end mr-1">
        <b-col>
          <b-button
            class="mr-1"
            variant="success"
            @click="routeToEditView(0)"
          >
            {{ $t("Edit") }}
          </b-button>

          <b-button
            variant="primary"
            @click="routeToEditView(1)"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </b-col>
      </b-row>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-body>
            <b-row class="infoContent mb-4">
              <b-col
                lg="12"
                class="ml-2 mb-2"
              >
                <!-- Title -->
                <div class="m-2 mb-sm-2 text-center">
                  <h3 class="m-2">
                    {{ name }}
                  </h3>
                  <h5 v-if="campaing">
                    {{ campaing.name }}
                  </h5>
                  <span
                    v-if="isActive"
                    class="text-success"
                  >{{
                    $t("dataGeneric.active")
                  }}</span>
                  <span
                    v-else
                    class="text-danger"
                  >{{
                    $t("dataGeneric.inactive")
                  }}</span>
                </div>

                <!-- Description -->
                <div class="m-2">
                  <b-form-textarea
                    id="textarea"
                    v-model="description"
                    :placeholder="$t('dataGeneric.description')"
                    rows="3"
                    max-rows="6"
                    disabled
                  />
                </div>

                <b-row>
                  <b-col>
                    <div class="m-2 mb-sm-2">
                      <b-card-text>{{ $t("code.impresiones") }}: {{ prints }}</b-card-text>
                      <small class="text-primary"> {{ $t("code.nVis") }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="m-2 mb-sm-2">
                      <b-card-text>{{ $t("code.nVis2") }}: {{ nDisplay }}</b-card-text>
                      <small class="text-primary">
                        {{ $t("code.nVis3") }}</small>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="m-2 mb-sm-2">
                      <b-card-text>{{ $t("code.seg") }}: {{ timeout }}</b-card-text>
                      <small class="text-primary">
                        {{ $t("code.seg2") }}
                      </small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="m-2 mb-sm-2">
                      <b-card-text>
                        {{ $t("code.momento") }}:
                        {{ typeOptions[type] }}
                      </b-card-text>
                      <small class="text-primary">
                        {{ $t("code.momento2") }}</small>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="content">
      <b-col>
        <b-card no-body>
          <b-card-body>
            <b-row class="infoContent mb-4">
              <b-col>
                <div class="m-2 mb-sm-2 text-center">
                  <h4>{{ content.name }}</h4>
                  <audio
                    v-if="content.resources.edges[0].node.videotype == 'AUR'"
                    :key="resourceId"
                    controls
                  >
                    <source
                      v-if="content.resources.edges[0].node.directUrl != ''"
                      :src="content.resources.edges[0].node.directUrl"
                      type="audio/mp3"
                    >
                    <source
                      v-else
                      :src="content.resources.edges[0].node.bucketUrl"
                      type="audio/mp3"
                    >
                  </audio>
                  <video
                    v-else
                    id="demo"
                    :key="content.id"
                    width="500"
                    height="305"
                    controls
                    class="
                      video-js
                      vjs-default-skin vjs-big-play-centered
                      tamanio
                    "
                    data-setup="{&quot;liveui&quot;: true}"
                    @loadeddata="videoMaker()"
                    @loadstart="videoMaker()"
                  >
                    <source
                      v-if="content.resources.edges[0].node.directUrl != ''"
                      :src="content.resources.edges[0].node.directUrl"
                      type="application/x-mpegURL"
                    >
                    <source
                      v-else
                      :src="content.resources.edges[0].node.bucketUrl"
                      type="video/mp4"
                    >
                  </video>
                  <br>
                  <small class="text-primary"> {{ $t("code.content") }}</small>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="vacioCat">
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t("code.categ") }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                lg="12"
                class="ml-2 mb-2"
              >
                <draggable
                  :list="categories"
                  tag="ul"
                  group="people"
                  class="scroll-list list-group list-group-flush"
                >
                  <b-list-group-item
                    v-for="cat in categories"
                    :key="cat.node.id"
                    tag="li"
                    disabled
                  >
                    <div class="d-flex">
                      <div class="ml-25">
                        <b-card-text class="mb-0 font-weight-bold">
                          {{ cat.node.name }}
                        </b-card-text>
                      </div>
                    </div>
                  </b-list-group-item>
                </draggable>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col v-if="vacioCon">
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t("code.contCatg") }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                lg="12"
                class="ml-2 mb-2"
              >
                <draggable
                  :list="contents"
                  tag="ul"
                  group="people"
                  class="scroll-list list-group list-group-flush"
                >
                  <b-list-group-item
                    v-for="cat in contents"
                    :key="cat.node.id"
                    tag="li"
                    disabled
                  >
                    <div class="d-flex">
                      <div class="ml-25">
                        <b-card-text class="mb-0 font-weight-bold">
                          {{ cat.node.name }}
                        </b-card-text>
                      </div>
                    </div>
                  </b-list-group-item>
                </draggable>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BFormTextarea,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError } from '@/store/functions'

import draggable from 'vuedraggable'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BListGroupItem,
    draggable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: getUserData(),
      video: null,
      ads: null,
      name: null,
      description: null,
      type: null,
      campaing: null,
      content: null,
      categories: [],
      contents: [],
      prints: null,
      timeout: null,
      isActive: null,
      nDisplay: null,
      vacioCat: false,
      vacioCon: false,
      typeOptions: {
        PRE: 'Preroll',
        MID: 'Midroll',
        POS: 'Postroll',
      },
    }
  },
  mounted() {
    this.getData()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    getData() {
      const { id } = this.$route.params

      axios
        .post('', {
          query: `
           query{
            allAds(id:"${id}",client:"${this.userData.profile.client.id}"){
              edges {
                node {
                  id
                  name
                  description
                  type                  
                  campaing{
                    name
                  }
                  content {
                    id
                    name
                    resources{
                      edges {
                        node {
                          videotype
                          directUrl
                          bucketUrl
                          file {                           
                            media
                          }
                        }
                      }
                    }
                  }
                  categories{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }                  
                  contents{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  prints
                  nDisplay
                  timeout
                  isActive                  
                }
              }
            }              
          }          
        `,
        })
        .then(result => {
          messageError(result, this)

          this.ads = result.data.data.allAds.edges[0].node
          this.name = this.ads.name
          this.description = this.ads.description
          this.type = this.ads.type
          this.campaing = this.ads.campaing
          this.content = this.ads.content
          this.categories = this.ads.categories.edges
          this.contents = this.ads.contents.edges
          this.prints = this.ads.prints
          this.timeout = this.ads.timeout
          this.isActive = this.ads.isActive
          this.nDisplay = this.ads.nDisplay
          this.vacioCat = this.categories.length !== 0

          this.vacioCon = this.contents.length !== 0
        })
        .catch(() => { })
    },
    routeToEditView(type) {
      const { id } = this.$route.params
      const { campaign } = this.$route.params
      switch (type) {
        case 0:
          this.$router.push({ name: 'ads-edit', params: { id } })
          break
        default:
          this.$router.push({
            name: 'ads-list',
            params: { campaign },
          })
          break
      }
    },
    videoMaker() {
      this.video = videojs('demo')
      this.video.on('ready', function () {
        try {
          this.video.vhs = null
        // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
  },
}
</script>
<style  lang="scss" scoped >
#ads-view .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#ads-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#ads-view .card-body h4 {
  font-size: 1.286rem !important;
}

#ads-view .tamanio {
  width: 45vw;
  height: 30vw;
}
</style>
